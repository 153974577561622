import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { USER_READ_ARTICLES, ARTICLE_BY_ID_URL } from "@/app/_consts/internal";

export interface UserInfo {
  firstName: string;
  lastName: string;
  permissions?: string[];
}

export interface UserReadArticlesState {
  isLoading: boolean;
  firstName: string;
  lastName: string;
  articles: any;
  dailyRead: any;
  userInfo: UserInfo | null;
}

const initialState: UserReadArticlesState = {
  isLoading: true,
  firstName: "",
  lastName: "",
  articles: [],
  dailyRead: {},
  userInfo: null,
};

export const fetchUserReadArticles = createAsyncThunk<any, string>(
  "userReadArticles/fetchUserReadArticles",
  async (accessToken) => {
    const response = await axios.get(USER_READ_ARTICLES, {
      headers: { Authorization: accessToken },
    });
    return response.data;
  },
);

export const saveUserReadArticle = createAsyncThunk<any, any>(
  "userReadArticles/saveUserReadArticle",
  async (data) => {
    const { accessToken, articleId } = data;
    const response = await axios.put(
      `${ARTICLE_BY_ID_URL}/${articleId}/read`,
      "",
      { headers: { Authorization: accessToken } },
    );
    const newArticlesRead = response.data;
    return newArticlesRead;
  },
);

const userReadArticlesSlice = createSlice({
  name: "userReadArticles",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserReadArticles.pending, (state) => {
        state.isLoading = true;
        state.articles = [];
        state.dailyRead = {};
        state.userInfo = null;
      })
      .addCase(fetchUserReadArticles.fulfilled, (state, action) => {
        state.isLoading = false;
        state.firstName = action.payload.userInfo.firstName;
        state.lastName = action.payload.userInfo.lastName;
        state.articles = action.payload.articles.articlesRead;
        state.dailyRead = action.payload.articles.dailyRead;
        state.userInfo = action.payload.userInfo;
      })
      .addCase(fetchUserReadArticles.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(saveUserReadArticle.fulfilled, (state, action) => {
        state.articles = action.payload.user_item.articlesRead;
        state.dailyRead = action.payload.user_item.dailyRead;
      });
  },
});

export default userReadArticlesSlice.reducer;
