"use client";

import * as React from "react";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";

import { cn } from "@/app/_utils/ui";

const TooltipProvider = TooltipPrimitive.Provider;

const Tooltip = TooltipPrimitive.Root;

interface TooltipTriggerProps
  extends React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Trigger> {
  children: React.ReactNode;
}

const TooltipTrigger: React.FC<TooltipTriggerProps> = ({
  children,
  ...props
}) => (
  <TooltipPrimitive.Trigger {...props}>{children}</TooltipPrimitive.Trigger>
);

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content> & {
    align?: "start" | "center" | "end";
  }
>(({ className, sideOffset = 40, align = "center", ...props }, ref) => (
  <TooltipPrimitive.Portal>
    <>
      <TooltipPrimitive.Content
        ref={ref}
        align={align}
        sideOffset={sideOffset}
        className={cn(
          "z-50 overflow-hidden rounded-sm bg-dark px-2 py-1.5 text-base text-white animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
          className,
        )}
        {...props}
      />
    </>
  </TooltipPrimitive.Portal>
));
TooltipContent.displayName = TooltipPrimitive.Content.displayName;

export const ClickableTooltip = React.forwardRef<
  { setOpen: (open: boolean) => void },
  {
    trigger: React.ReactNode;
    content: React.ReactNode;
    align?: "start" | "center" | "end";
    contentClassname?: string;
    closeAfterTimeout?: number;
  }
>(
  (
    { trigger, content, align = "center", contentClassname, closeAfterTimeout },
    ref,
  ) => {
    const [open, setOpen] = React.useState(false);

    React.useImperativeHandle(ref, () => ({ setOpen }));

    const handleToggle = () => setOpen((prev) => !prev);

    React.useEffect(() => {
      if (open && closeAfterTimeout) {
        const timeoutId = setTimeout(() => {
          setOpen(false);
        }, closeAfterTimeout);

        return () => clearTimeout(timeoutId);
      }
    }, [open, closeAfterTimeout]);

    return (
      <TooltipProvider>
        <Tooltip open={open} onOpenChange={setOpen}>
          <TooltipTrigger asChild>
            <div onClick={handleToggle}>{trigger}</div>
          </TooltipTrigger>
          <TooltipContent align={align} className={contentClassname}>
            {content}
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    );
  },
);

ClickableTooltip.displayName = "ClickableTooltip";
